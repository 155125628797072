.image {
	height: min(250px, 19vmin);
	aspect-ratio: 1;
}
.button {
	display: inline-block;
	margin: 0 1.5vh;
	cursor: pointer;
}
.name {
	width: 0;
	min-width: 100%;
	text-align: center;
	color: white;
	font-size: var(--medium);
	margin: 1vh 0 2vh 0;
}
