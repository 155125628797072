.container {
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: var(--screen-padding);
}
.button-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.title {
	color: white;
	text-align: center;
	font-size: var(--large);
}