.container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: var(--light);
	position: relative;
}
.dropdown {
	position: relative;
}
.option {
	width: 100%;
	background-color: var(--light);
	right: 0;
	text-align: right;
	padding: 5px;
	padding-right: 15px;
	font-size: var(--small);
}
.option:hover {
	background: var(--hover);
}
.option-container {
	width: 100%;
	position: absolute;
}
.item {
	display: flex;
	align-items: center;
	padding: min(2vmin, 15px);
	margin: 0;
	font-size: var(--small);
	font-weight: 500;
}
.item:hover {
	background-color: var(--hover);
}
.email {
	color: var(--primary);
	font-style: italic;
	margin: 0;
}
.div-container {
	display: flex;
}