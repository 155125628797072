/* Variables */
:root {
	--primary: #47184B;
	--accent: #232156;
	--light: #e3e4e6;
	--hover: #cacccf;

	--small: 2vmin;
	--medium: 2.3vmin;
	--large: 4vmin;

	--screen-padding: 4vmin;
}

/* Default font and box sizing */
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
* {
	box-sizing: border-box;
}

/* Main containers */
#root {
	height: 100vh;
	display: flex;
	flex-direction: column;
	background-image: url("./assets/rotatedAppBackground.png");
	background-size: cover;
}
#main {
	margin: 0 auto;
	width: 100vw;
	flex: 1;
}
