.grid {
	display: inline-grid;
	grid-template-columns: repeat(3, 1fr);
	grid-auto-columns: min-content;
}
.container {
	display: flex;
	flex-direction: column;
	align-items: center;
}
